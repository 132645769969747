<template>
  <v-container fluid>
  <ValidationObserver ref="obs" v-slot="{ handleSubmit }">
    <v-form @submit.prevent="handleSubmit(resetPassword)"
            class="row row--dense justify-center align-center">
      <v-col cols="12">
        <div class="title text-center">
          {{ $t('ResetPassword.title') }}
        </div>
      </v-col>
      <v-col cols="10" sm="12">
        <v-col cols="12">
          <div class="text-center text--secondary">
            {{ $t('ResetPassword.message') }}
          </div>
        </v-col>
        <v-col
          v-for="(field, index) in inputFields"
          :key="field.name"
          cols="12" sm="6" class="mx-auto pa-0"
        >
          <TextFieldWithValidation
            v-model="field.model"
            :name="field.name"
            outlined
            dense
            mode="eager"
            :label="field.label"
            :rules="field.rules"
            :type="field.type"
          />
          <v-col v-if="index === inputFields.length - 1" cols="12" sm="6">
            <router-link to="/login"
                         style="color: #3aa8cc; text-decoration: none;"
                         class="text-uppercase caption font-weight-bold"
            >{{ $t('ResetPassword.toLogin') }}</router-link>
          </v-col>
        </v-col>
      </v-col>
      <v-col cols="10" sm="6" md="4" class="text-center">
        <v-btn rounded color="secondary" type="submit" :loading="loading"
          >{{ $t('ResetPassword.confirmNewPassword') }}</v-btn
        >
      </v-col>
    </v-form>
  </ValidationObserver>
  </v-container>
</template>

<script>
export default {
  name: 'ResetPassword',
  data() {
    return {
      inputFields: [
        {
          name: 'password-reset',
          model: '',
          rules: 'required|min:10|password_complexity',
          label: this.$t('inputLabels.password'),
          type: 'password',
          class: ''
        },
        {
          name: 'password-reset-repeat',
          model: '',
          rules: 'required|min:8|password:@password-reset',
          label: this.$t('inputLabels.repeatPassword'),
          type: 'password',
          class: ''
        }
      ],
      loading: false
    };
  },
  methods: {
    async resetPassword() {
      try {
        if (this.$route.query.uid) {
          // eslint-disable-next-line camelcase
          const new_password = this.inputFields[0].model;
          this.loading = true;
          const payload = {
            ...this.$route.query,
            new_password
          };
          await this.$store.dispatch('user/confirmResetPassword', payload);
          this.$snackbar({ content: this.$t('ResetPassword.successMessage'), color: 'success' });
          this.$router.push('/login');
        }
      } catch (err) {
        this.$snackbar({ content: `${this.$t('ResetPassword.errorMessage')}: ${err.message}`, color: 'error' })
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>
<style scoped></style>
